import { cameraVerificationIssueToLabel, TCameraVerificationIssue } from '@th-common/enums/camera-verification/camera-verification.enum';
import { recentlySeenToLabel, TRecentlySeenKind } from '@th-common/enums/device-filter-kind';
import { IIdentity } from '@th-common/interfaces/lookup';

export enum TOperationalStatus {
  Unknown,
  Ok,
  Warning,
  Error,
}

export type TOperationalStatusFilter = TOperationalStatus | 'All';

export const operationalStatusToLabel: Record<TOperationalStatus, string> = {
  [TOperationalStatus.Unknown]: 'Never Connected',
  [TOperationalStatus.Ok]: 'No Issues',
  [TOperationalStatus.Warning]: 'Warning',
  [TOperationalStatus.Error]: 'Critical Warning',
};

export const operationalStatusToFilterList: IIdentity<TOperationalStatusFilter>[] = [
  { id: 'All', name: 'All Statuses' },
  { id: TOperationalStatus.Ok, name: operationalStatusToLabel[TOperationalStatus.Ok] },
  { id: TOperationalStatus.Warning, name: operationalStatusToLabel[TOperationalStatus.Warning] },
  { id: TOperationalStatus.Error, name: operationalStatusToLabel[TOperationalStatus.Error] },
  { id: TOperationalStatus.Unknown, name: operationalStatusToLabel[TOperationalStatus.Unknown] },
];

export type TCombinedFiltersType = 'All' | string;
export const combinedFiltersToFilterList: IIdentity<TCombinedFiltersType>[] = [
  {
    id: 'All',
    name: 'All Statuses',
  },
  {
    id: operationalStatusToLabel[TOperationalStatus.Error],
    name: operationalStatusToLabel[TOperationalStatus.Error],
  },
  {
    id: operationalStatusToLabel[TOperationalStatus.Warning],
    name: operationalStatusToLabel[TOperationalStatus.Warning],
  },
  {
    id: operationalStatusToLabel[TOperationalStatus.Ok],
    name: operationalStatusToLabel[TOperationalStatus.Ok],
  },
  { id: recentlySeenToLabel[TRecentlySeenKind.NotRecentlySeen],
    name: recentlySeenToLabel[TRecentlySeenKind.NotRecentlySeen] },
  {
    id: recentlySeenToLabel[TRecentlySeenKind.RecentlySeen],
    name: recentlySeenToLabel[TRecentlySeenKind.RecentlySeen],
  },
  {
    id: cameraVerificationIssueToLabel[TCameraVerificationIssue.RejectedImage],
    name: cameraVerificationIssueToLabel[TCameraVerificationIssue.RejectedImage],
  },
  {
    id: cameraVerificationIssueToLabel[TCameraVerificationIssue.MissingReference],
    name: cameraVerificationIssueToLabel[TCameraVerificationIssue.MissingReference],
  },
];
