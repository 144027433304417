import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({
  addTagTypes: ['TestTag'],
}).injectEndpoints({
  endpoints: builder => ({

  }),
  overrideExisting: false,
});
export default api;
