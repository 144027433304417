import { TJobType } from '@th-common/enums/job/job-type.enum';
import { ICameraShort } from '@th-common/interfaces/camera';
import {
  IDevice,
  IDeviceRecentVideoQuery,
  IDeviceRecording,
  IDeviceRecordingsSearchRequest,
  IDeviceSearchRequest,
  IRecentVideoQuerySearchRequest,
} from '@th-common/interfaces/device';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { apiRoot } from '@th-common/store/api';

interface IDeviceRecordingQueryParams {
  deviceId: number;
  searchRequest: IDeviceRecordingsSearchRequest;
}

interface IVideoQueryQueryParams {
  deviceId: number;
  searchRequest: IRecentVideoQuerySearchRequest;
  jobType: TJobType.UserDownload | TJobType.RidershipQuery;
}

export const deviceApiEndpoints = {
  getDevices: {
    query: (searchRequest: IDeviceSearchRequest) => {
      return {
        url: 'device/all',
        method: 'POST',
        body: searchRequest,
      };
    },
  },
};

export const deviceApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getDevices: builder.query<IPagedResult<IDevice>, IDeviceSearchRequest>(deviceApiEndpoints.getDevices),
    getDeviceRecordings: builder.query<IPagedResult<IDeviceRecording>, IDeviceRecordingQueryParams>({
      query: ({ deviceId, searchRequest }) => ({
        url: `device/${deviceId}/recordings/all`,
        method: 'POST',
        body: searchRequest,
      }),
    }),
    getRecentVideoQueries: builder.query<IPagedResult<IDeviceRecentVideoQuery>, IVideoQueryQueryParams>({
      query: ({ deviceId, searchRequest, jobType }) => ({
        url: `device/${deviceId}/${jobType === TJobType.UserDownload ? 'recent-video-queries' : 'recent-ridership-validations'}/all`,
        method: 'POST',
        body: searchRequest,
      }),
    }),
    getDeviceCameras: builder.query<ICameraShort[], number>({
      query: (deviceId) => `device/${deviceId}/cameras`,
    }),
    activeDevicesSameType: builder.query<boolean, void>({
      query: () => 'device/active-devices-same-type',
    }),
  }),
});
