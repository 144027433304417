/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { TMapProvider } from '@th-common/enums/client-settings/map-provider.enum';
import { IUserProfileClientSettings, IUserProfileMe } from '@th-common/interfaces/user-profile';

import { api as apiUserProfile } from './api';

export interface IUserProfileState {
  me: Omit<IUserProfileMe, 'clientSettings'>;
  clientSettings: IUserProfileClientSettings;
}

const initialState: IUserProfileState = {
  me: {
    userGroupIds: [],
    timeZoneId: null,
    timeZoneName: null,
    features: [],
    id: 0,
    email: '',
    userName: '',
    name: '',
    adReference: null,
  },
  clientSettings: {
    loggingEnabled: false,
    use12HourFormat: true,
    maxDownloadsToGet: 0,
    maxRecentMessagesToGet: 0,
    maxTotalMessagesToGet: 0,
    unitOfSpeed: 0,
    unitOfTemperature: 0,
    showDatabaseIdColumns: false,
    showMostRecentVideoDownloadsFirst: false,
    showMostRecentData: false,
    markedVideoLeadTime: 0,
    markedVideoPostTime: 0,
    mapProvider: TMapProvider.GMAPS,
    offlineBeaconListenerPort: 0,
    defaultGeolocationArea: null,
  },
};

export const slice = createSlice({
  name: 'user-profile',
  initialState,
  reducers: {
    reset: () => initialState,
    setUserProfileMe: (state, { payload }) => {
      state.me = payload;
    },
    setUserClientSettings: (state, { payload }: { payload: IUserProfileClientSettings }) => {
      state.clientSettings = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IUserProfileState>) => {
    builder.addMatcher(apiUserProfile.endpoints.getUserProfileMe.matchFulfilled, (state, { payload }: { payload: IUserProfileMe }) => {
      const { clientSettings, ...me } = payload;
      state.me = me;

      state.clientSettings = clientSettings;
    });
  },
});

export default slice.reducer;
