import { IUserProfileChangePassword, IUserProfileClientSettings, IUserProfileMe } from '@th-common/interfaces/user-profile';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUserProfileMe: builder.query<IUserProfileMe, void>({
      query: () => 'userprofile/me',
    }),
    changePassword: builder.mutation<boolean, IUserProfileChangePassword>({
      query: (request: IUserProfileChangePassword) => {
        return {
          url: 'userprofile/change-password',
          method: 'PUT',
          body: request,
        };
      },
    }),
    clientSettings: builder.mutation<boolean, IUserProfileClientSettings>({
      query: (clientSettings: IUserProfileClientSettings) => {
        return {
          url: 'userprofile/client-settings',
          method: 'PUT',
          body: clientSettings,
        };
      },
    }),
  }),
});
