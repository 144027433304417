import { deviceApiEndpoints } from '@th-common/api/device.api';
import { THttpStatusCode } from '@th-common/enums/http/http-status-code';
import { IDevice, IDeviceDetails, IDeviceSearchRequest, IFirmwareRevision } from '@th-common/interfaces/device';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { apiRoot } from '@th-common/store/api';

export const api = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getFleetDevices: builder.query<IPagedResult<IDevice>, IDeviceSearchRequest>(deviceApiEndpoints.getDevices),
    getDeviceDetails: builder.query<IDeviceDetails, number>({
      query: (deviceId: number) => `device/${deviceId}`,
      extraOptions: {
        errorStatusToMessage: {
          [THttpStatusCode.NotFound]: 'Device was not found.',
        },
      },
    }),
    getFirmwareRevisions: builder.query<IFirmwareRevision[], void>({
      query: () => 'device/firmware-revisions',
    }),
    getDeviceTypes: builder.query<string[], void>({
      query: () => 'device/types',
    }),
  }),
});
