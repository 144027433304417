export enum TMapProvider {
  /// <summary>
  /// No map provider
  /// </summary>
  NONE = 0,
  /// <summary>
  /// Google Maps
  /// </summary>
  GMAPS = 1,
  /// <summary>
  /// Open Street Maps
  /// </summary>
  OSM = 2,
  /// <summary>
  /// Bing Maps
  /// </summary>
  // BING = 3, // Commented for a while we don't use it yet
}

export const mapProviderToLabel: Record<TMapProvider, string> = {
  [TMapProvider.NONE]: 'Disable GPS Map',
  [TMapProvider.GMAPS]: 'Google Maps',
  [TMapProvider.OSM]: 'Open Street Maps',
  // Commented for a while we don't use it yet
  // [TMapProvider.BING]: 'Bing Maps',
};

export const mapProvidersList = [
  { value: TMapProvider.GMAPS, title: mapProviderToLabel[TMapProvider.GMAPS] },
  { value: TMapProvider.OSM, title: mapProviderToLabel[TMapProvider.OSM] },
  // Commented for a while we don't use it yet
  // { value: TMapProvider.BING, title: mapProviderToLabel[TMapProvider.BING] },
];
