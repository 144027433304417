import { api } from './api';
import { selectors } from './selectors';
import { slice } from './slice';

// Facade for the Common store
const commonStore: {
  actions: typeof slice.actions;
  selectors: typeof selectors;
  api: typeof api;
} = {
  actions: slice.actions,
  selectors,
  api,
};

export { commonStore };
