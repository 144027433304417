/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { TDeviceTimeoutScale } from '@th-common/enums/system/server-config.enum';
import { IServerConfig } from '@th-common/interfaces/system';

import { api as apiSystem } from './api';

export interface ISystemState {
  serverConfig: IServerConfig;
}

const initialState: ISystemState = {
  serverConfig: {
    deviceTimeoutToUnkownValue: 0,
    deviceTimeoutScale: TDeviceTimeoutScale.Days,
    licenseValidUntil: '',
    licensedFeatures: [],
  },
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<ISystemState>) => {
    builder.addMatcher(
      apiSystem.endpoints.serverConfig.matchFulfilled,
      (state, { payload }: { payload: IServerConfig }) => {
        state.serverConfig = payload;
      },
    );
  },
});

export default systemSlice.reducer;
