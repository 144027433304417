/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface IState {
  data: any;
  isAuthenticated: boolean | null;
}
const initialState: IState = {
  data: 'testState',
  isAuthenticated: null,
};
export const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    reset: () => initialState,
    setAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
  },
  extraReducers: () => {},
});

export default slice.reducer;
