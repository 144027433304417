/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { cameraVerificationIssueToLabel } from '@th-common/enums/camera-verification/camera-verification.enum';
import { deviceFilterTitleToKind, recentlySeenToLabel, TDeviceFilterKind } from '@th-common/enums/device-filter-kind';
import { operationalStatusToLabel, TCombinedFiltersType } from '@th-common/enums/status/operational-status.enum';
import {
  IDevice,
  IDeviceDetails,
  IDeviceFiltersFormValues,
  IDeviceSearchRequest,
  TDevicesFiltered,
} from '@th-common/interfaces/device';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import dayjs from 'dayjs';

import { api as apiDevice } from './api';

export interface IDeviceState {
  // Devices
  devices: IDevice[];
  combinedFilter: TCombinedFiltersType;
  searchRequest: IDeviceSearchRequest;
  devicesListLoading: boolean;
  pagination: Omit<IPagedResult<IDevice>, 'items'>;
  lastTimeUpdated: string;
  // Device Details12
  activeDeviceIndex: number | null;
  activeDeviceId: number | null;
  activeDevice: IDeviceDetails | null;
  activeStatusId: number | null;

  navigationInProgress: boolean;
}

export const defaultFilters: TDevicesFiltered = {
  overallStatus: null,
  deviceFilterKind: TDeviceFilterKind.All,
  configGroupId: null,
  firmwareRevision: null,
  recentlySeen: null,
  cameraIssues: null,
  type: null,
  orderBy: [
    {
      fieldName: 'timestamp',
      ascending: false,
    },
  ],
};

const initialState: IDeviceState = {
  // Devices
  devices: [],
  combinedFilter: 'All',
  searchRequest: {
    page: 1,
    pageSize: 36,
    searches: [],
    searchAsOneWord: true,
    ...defaultFilters,
  },
  devicesListLoading: false,
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  lastTimeUpdated: dayjs().toISOString(),
  // Device Details
  activeDeviceIndex: null,
  activeDeviceId: null,
  activeDevice: null,
  activeStatusId: null,
  navigationInProgress: false,
};

let lastDevicesRequestId: string | null = null;
let lastDeviceDetailsRequestId: string | null = null;

function getKeyByValue<T>(obj: Record<string, string>, value: string, isBool = false): T | null {
  const keyResult = Object.keys(obj).find((key: string) => obj[key] === value);
  return ((keyResult ? (isBool ? !!Number(keyResult) : Number(keyResult)) : null) as T) ?? null;
}

function convertFormValueToSearchRequestFilters(formValues: IDeviceFiltersFormValues): TDevicesFiltered {
  return {
    deviceFilterKind: ['All', 'Online'].includes(formValues.configGroupId as string)
      ? deviceFilterTitleToKind[formValues.configGroupId]
      : TDeviceFilterKind.ConfigGroup,
    configGroupId: formValues.configGroupId === 'All' || formValues.configGroupId === 'Online'
      ? null
      : formValues.configGroupId,
    overallStatus: getKeyByValue(operationalStatusToLabel, formValues.combinedFilter),
    firmwareRevision: formValues.firmwareRevision === 'All'
      ? null
      : formValues.firmwareRevision,
    recentlySeen: getKeyByValue(recentlySeenToLabel, formValues.combinedFilter, true),
    cameraIssues: getKeyByValue(cameraVerificationIssueToLabel, formValues.combinedFilter),
    type: formValues.type === 'All' ? null : formValues.type,
    orderBy: [
      {
        fieldName: 'timestamp',
        ascending: formValues.orderByDirection === 'asc',
      },
    ],
  };
}

export const slice = createSlice({
  name: 'fleet',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    formFilterDevices: (state, { payload }) => {
      state.combinedFilter = payload.combinedFilter;
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(payload),
      };
    },
    setDevicesLoading: (state, { payload }) => {
      state.devicesListLoading = payload;
    },
    search: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
    },
    resetDevices: (state) => {
      state.devices = [];
    },
    resetPage: (state) => {
      state.devices = [];
      state.searchRequest.page = 1;
    },
    setPage: (state, { payload }) => {
      state.searchRequest.page = payload;
    },
    setDetailsPage: (state, { payload }) => {
      if (payload < state.searchRequest.page) {
        state.activeDeviceIndex = state.searchRequest.pageSize - 1;
      } else {
        state.activeDeviceIndex = 0;
      }
      state.searchRequest.page = payload;
      state.activeStatusId = null;
    },
    navigationInProgress: (state, { payload }: { payload: boolean }) => {
      state.navigationInProgress = payload;
    },
    setActiveDeviceIndex: (state, { payload }: {
      payload: {
        deviceIndex: number | null;
        statusId?: number;
      };
    }) => {
      state.activeDeviceIndex = payload.deviceIndex;

      if (payload.deviceIndex === null) {
        state.activeDeviceId = null;
        state.activeDevice = null;
        state.activeStatusId = null;
      } else {
        const device = state.devices[payload.deviceIndex];
        state.activeDeviceId = state.devices[payload.deviceIndex]?.id || null;

        if (device) {
          state.activeDevice = {
            ...(state.activeDevice || {}),
            name: device.name,
            deviceType: device.deviceType,
          } as IDeviceDetails;

          if (payload.statusId) {
            state.activeStatusId = payload.statusId;
          } else {
            state.activeStatusId = null;
          }
        }
      }
    },
    setActiveStatusId: (state, { payload }) => {
      state.activeStatusId = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IDeviceState>) => {
    builder
      .addMatcher(apiDevice.endpoints.getFleetDevices.matchPending, (state, { meta }) => {
        lastDevicesRequestId = meta.requestId;
      })
      .addMatcher(apiDevice.endpoints.getFleetDevices.matchFulfilled, (state, { payload, meta }) => {
        if (lastDevicesRequestId === meta.requestId) {
          const { items, ...pagination } = payload;

          if (state.activeDeviceIndex !== null) {
            state.activeDeviceId = items[state.activeDeviceIndex]?.id || null;
          }
          state.devices = items || [];
          state.pagination = pagination;
          state.lastTimeUpdated = dayjs().toISOString();
        }
      })
      .addMatcher(apiDevice.endpoints.getDeviceDetails.matchPending, (state, { meta }) => {
        lastDeviceDetailsRequestId = meta.requestId;
      })
      .addMatcher(apiDevice.endpoints.getDeviceDetails.matchFulfilled, (state, { payload, meta }) => {
        if (lastDeviceDetailsRequestId === meta.requestId) {
          state.activeDevice = payload;
        }
      });
  },
});

export default slice.reducer;
