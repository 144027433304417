import { api } from '@th-common/store/system/api';
import { selectors } from '@th-common/store/system/selectors';
import { systemSlice } from '@th-common/store/system/slice';

interface ISystemStore {
  api: typeof api;
  actions: typeof systemSlice.actions;
  selectors: typeof selectors;
}

const systemStore: ISystemStore = {
  api,
  actions: systemSlice.actions,
  selectors,
};

export { systemStore };
