export enum TDeviceFilterKind {
  All = 1,
  Online,
  ConfigGroup,
}

export type TDeviceFilterValue = 'All' | 'Online' | number;

export const deviceFilterTitleToKind: Record<string, TDeviceFilterKind> = {
  All: TDeviceFilterKind.All,
  Online: TDeviceFilterKind.Online,
  ConfigGroup: TDeviceFilterKind.ConfigGroup,
};

export const deviceFilterKindToFilterValue:
Record<TDeviceFilterKind.All, 'All'> & Record<TDeviceFilterKind.Online, 'Online'> = {
  [TDeviceFilterKind.All]: 'All',
  [TDeviceFilterKind.Online]: 'Online',
};

export enum TRecentlySeenKind {
  NotRecentlySeen,
  RecentlySeen,
  All,
}

export const recentlySeenToLabel: Record<TRecentlySeenKind, string> = {
  [TRecentlySeenKind.RecentlySeen]: 'Recently Seen',
  [TRecentlySeenKind.NotRecentlySeen]: 'Not Recently Seen',
  [TRecentlySeenKind.All]: 'All Devices',
};
